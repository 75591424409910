$(document).ready(function(){
  $("#swipeable-arrow li a").on("click", function(e){
    e.preventDefault();
    var hrefval = $(this).attr("href");
    
    if(hrefval == "#left-collapse-content") {
      var distance = $('#right-swap-maincontain').css('left');
      
      if(distance == "auto" || distance == "0px") {
        $(this).addClass("open");
        openSidepage();
      } else {
        closeSidepage();
      }
    }
  }); // end click event handler
  
  $("#swipeable-arrow li a").on("hover", function(){
    var classval = $(this).hasClass("hovertrigger");
    
    if(classval == true) {
      var distance = $('#right-swap-maincontain').css('left');
      
      if(distance == "auto" || distance == "0px") {
        $(this).addClass("open");
        openSidepage();
      }
    }
  }); // end hover event handler
  
  $("#closebtn").on("click", function(e){
    e.preventDefault();
    closeSidepage();
  }); // end close button event handler

  function openSidepage() {
    $('#right-swap-maincontain').animate({
      left: '550px'
    }, 400, 'easeOutBack'); 
  }
  
  function closeSidepage(){
    $("#swipeable-arrow li a").removeClass("open");
    $('#right-swap-maincontain').animate({
      left: '0'
    }, 400, 'easeOutQuint');  
  }
}); 